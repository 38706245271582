import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

type CalculateResponse = Array<{
  calculation: {
    interval: { Нижний: string; Верхний: string };
    interval_summary: any;
    item_index: number;
    ration_count: any;
    ration_result: any;
  };
  filename: string;
}>;

export const loadFiles = async (
  files: Array<File>,
  intervals: Array<{ [key: string]: Array<{ low: number; high: number }> }> // Изменение типа интервалов на number
) => {
  const formData = new FormData();

  // Корректное добавление файлов
  files.forEach((file) => {
    formData.append("files", file);
  });

  // Подготовка intervals_map с верхним ключом 'intervals'
  const intervalsMap = { intervals: intervals };

  // Добавление intervals_map как JSON строку
  formData.append("intervals_map", JSON.stringify(intervalsMap));

  try {
    const res = await axios.post("/process/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
};

export const createExcel = async (response: CalculateResponse) => {
  const formData = new FormData();

  // Добавляем данные JSON для создания Excel
  formData.append("data_json", JSON.stringify(response)); // Упрощенная отправка данных

  try {
    const res = await axios.post("/create_excel/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    });

    // Обработка ответа
    const contentType = res.headers["content-type"];
    let filename = "sample_data.xlsx"; // Значение по умолчанию
    const disposition = res.headers["content-disposition"];

    if (disposition) {
      const filenameMatch = disposition.match(/filename\*\=UTF-8''(.+)/);
      if (filenameMatch && filenameMatch.length === 2) {
        filename = decodeURIComponent(filenameMatch[1]);
      }
    }

    const blob = new Blob([res.data], { type: contentType });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error creating Excel file:", error);
  }
};
