import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import DropSVG from "../../static/svg/drop.svg";

import { addFile } from "../../store/slices/files";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
  width: 939px;
  height: 419px;
  border: 3px solid rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledText = styled.p`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
`;

const StyledDropSVG = styled.div`
  background: url(${DropSVG});
  width: 50px;
  height: 50px;
`;

function Dropzone() {
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((item: File) => {
      return dispatch(
        addFile({ file: item, intervals: [{ low: "", high: "" }] })
      );
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <StyledDropzone {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledDropSVG />
      {isDragActive ? (
        <StyledText>Переместите файл сюда</StyledText>
      ) : (
        <StyledText>Перетащите файлы для расчетов сюда</StyledText>
      )}
    </StyledDropzone>
  );
}

export default Dropzone;
