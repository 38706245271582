import React from "react";
import styled from "styled-components";

import TrashSVG from "../../../static/svg/trash.svg";
import Input from "../../Input";
import Button from "../../Button";

import { Interval } from "../../../store/slices/files";
import { useDispatch } from "react-redux";

import {
  changeInterval,
  addInterval,
  removeInterval,
  removeFile,
} from "../../../store/slices/files";

const StyledFile = styled.div`
  display: flex;

  width: 951px;

  margin-bottom: 16px;

  background: rgba(212, 226, 253, 0.5);
  border-radius: 5px;
  padding: 16px;
`;

const StyledFileTitle = styled.div`
  display: flex;
  font-size: 24px;
`;
const StyledDeleteIcon = styled.div`
  background: url(${TrashSVG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-right: 6px;

  cursor: pointer;
`;

const StyledInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
`;

const StyledInput = styled.div`
  display: flex;
  gap: 8px;
`;

interface Props {
  index: number;
  name: string;
  intervals: Array<Interval>;
}

function File({ index, name, intervals }: Props) {
  const dispatch = useDispatch();

  const onLowInputChange = (payload: string, intervalIndex: number) => {
    dispatch(
      changeInterval({ key: "low", index, intervalIndex, value: payload })
    );
  };

  const onHighInputChange = (payload: string, intervalIndex: number) => {
    dispatch(
      changeInterval({ key: "high", index, intervalIndex, value: payload })
    );
  };

  const onAddIntervalClick = () => {
    dispatch(addInterval({ index }));
  };

  const onRemoveIntervalClick = (intervalIndex: number) => {
    dispatch(removeInterval({ index, intervalIndex }));
  };

  const onRemoveFileClick = () => {
    dispatch(removeFile({ index }));
  };

  return (
    <StyledFile>
      <StyledFileTitle>
        <StyledDeleteIcon onClick={() => onRemoveFileClick()} />
        <span>{name}</span>
      </StyledFileTitle>
      <StyledInputsWrapper>
        {intervals.map((interval, index) => {
          return (
            <StyledInput>
              <Input
                index={index}
                placeholder="Нижний"
                value={interval.low}
                onChange={onLowInputChange}
              />
              <Input
                index={index}
                placeholder="Верхний"
                value={interval.high}
                onChange={onHighInputChange}
              />
              <Button
                icon="cross"
                isSVG
                onClick={() => onRemoveIntervalClick(index)}
              />
              {index === 0 && (
                <Button
                  disabled={intervals.length >= 4}
                  icon="plus-black"
                  isSVG
                  onClick={onAddIntervalClick}
                />
              )}
            </StyledInput>
          );
        })}
      </StyledInputsWrapper>
    </StyledFile>
  );
}

export default File;
